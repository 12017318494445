@use "bulma/sass/utilities/mixins";

$grey: #4a437f79;

.App {
  margin: 20px;
}

.SearchForm-wrapper {
  display: flex;
  justify-content: right;
  z-index: 5;

  .control {
    font-size: medium;
  }

  @include mixins.mobile {
    form {
      width: 100%;

      .field.has-addons .control:first-child {
        width: 100%;
      }

      input {
        width: 100%;
      }
    }
  }
}

.wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-grow: 1;

  @include mixins.mobile {
    margin-top: 6rem;
  }
}

.wrapper:has(img){
  margin-top: 2rem;
  @include mixins.mobile{
    flex-direction: column-reverse;
    margin-top: 6rem;
  }
  @include mixins.tablet{
    flex-direction: column-reverse;
  }
  @include mixins.desktop{
    flex-direction: row;
  }
}

.TitleContainer {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  margin: 0 auto;
  max-width: 35rem;
  perspective: 200px;
  position: relative;

  h1 {
    font-size: 4rem;
    color: whitesmoke;
  }

  p {
    font-size: 1.3rem;
  }

  @include mixins.mobile{
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.1rem;
    }
  }
  @include mixins.tablet{
    h1 {
      font-size: 3rem;
    }
  }
}

.MoviesList {
  margin-top: 30px;
}

.MoviesList-item {
  margin-bottom: 2.5rem;
  border-radius: 0.75rem;
  max-height: 440px;
  transition: all .2s ease-in-out;
}

.MoviesList-item:has(> .card:hover) { 
  scale: 1.1;
}

.hide {
  display: none;
}

.card{
  transition: all .2s ease-in-out;
}

.card:hover > .hide {
  display: block;
}

/*---------- Loading ------------*/
.loader {
  padding: 8px;
  border-radius: 50%;
  background: #25b09b;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
}
@keyframes spin {to{transform: rotate(1turn)}}

/*---------- Error css ----------*/
.not-found-container{
    position: fixed;
    display: flex;
    img{
      align-self: flex-start;
    }
}

.attribution{
  position: fixed;
  bottom: 20px;
  right: 0;
  width: 300px;
}

/* ---------- Orbit css ---------- */
.streaming-logo-orbit {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px $grey solid;
  background-color: $grey;
  z-index: -5;
}

#circle-orbit-container {
  position: fixed;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 75px;
  z-index: -10;

  @include mixins.mobile {
    display: none;
  }
}

#inner-orbit {
  position: absolute;
  top: 9px;
  width: 643px;
  height: 643px;
  border: 1px $grey solid;
  border-radius: 100%;
  -webkit-animation: spin-right 25s linear infinite;
  animation: spin-right 25s linear infinite;
}

#middle-orbit {
  position: absolute;
  top: -166px;
  width: 1000px;
  height: 1000px;
  border: 1px $grey solid;
  border-radius: 100%;
  -webkit-animation: spin-right 25s linear infinite;
  animation: spin-right 25s linear infinite;
}

#outer-orbit {
  position: absolute;
  top: -323px;
  width: 1357px;
  height: 1357px;
  border: 1px $grey solid;
  border-radius: 100%;
  -webkit-animation: spin-right 30s linear infinite;
  animation: spin-right 30s linear infinite;
}

/* ---------- Animation ---------- */
@keyframes spin-right {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}